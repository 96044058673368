


















import {
  defineComponent,
  computed,
  useContext,
  useFetch,
  watch,
  onMounted,
  ref,
  onActivated,
  onDeactivated
} from '@nuxtjs/composition-api';
import { usePageStore } from '~/stores/page';
import { useApi } from '~/composables';
import CATEGORY from '~/modules/catalog/pages/category.vue';
import CMS_PAGE from '~/pages/Cms.vue';
import ERROR from '~/layouts/error.vue';
import PRODUCT from '~/modules/catalog/pages/product.vue';
import { RoutableInterface } from '~/modules/GraphQL/types';
import FortytwoSearchPage from '~/modules/catalog/pages/search.vue';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: 'PageResolver',
  components: {
    CATEGORY,
    CMS_PAGE,
    PRODUCT,
    ERROR,
    FortytwoSearchPage,
    SkeletonLoader,
  },

  setup() {

    const routeData = ref();
    const { query } = useApi();
    const context = useContext();
    const { path } = context.route.value;
    const routeError = ref(null);
    const dataProductSku = ref();
    const routeSearch = ref(false);
    let stopHandles = [];

    const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

    useFetch(async () => {
      if(clearUrl == "/search"){
        routeSearch.value = true;
      }
      else{
        const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);
        const results: RoutableInterface | null = data?.route ?? null;
        if (!results || errors?.length) {
          context.error({ statusCode: 404 });
          routeError.value = { statusCode: 404 };
        };
        routeData.value = results;
      }
    })

    const fetchDYScript = async () => {
      if(routeData?.value?.type == 'PRODUCT'){
        if(routeData?.value?.__typename == "SimpleProduct"){
          const myGraphqlQuery = `
            query {
              products(filter: { sku: { eq: "${routeData.value.sku}" } }) {
                  items {
                    msbc_item_no
                  }
                }
            }`;
          const dataMSBC :any = await query(myGraphqlQuery);
          dataProductSku.value = dataMSBC?.data.products.items[0].msbc_item_no;
        }else{
          dataProductSku.value = routeData.value.sku;
        }
          window.DY = window.DY || {};
          window.DY.recommendationContext = {type: "PRODUCT", data: [dataProductSku.value]};
      }
    }

    const startWatchers = () => {
      stopHandles.push(
        watch(
          () => routeData.value,
          (newVal) => {
            if (newVal !== null && newVal !== ""){
              fetchDYScript();
            }
          },
        )
      );
    };

    const stopWatchers = () => {
      stopHandles.forEach((stop) => stop());
      stopHandles = [];
    };

    startWatchers();

    onActivated(() => {
      startWatchers();
    });

    onDeactivated(() =>{
      stopWatchers();
    });

    onMounted(() => {
      fetchDYScript();
    });

    // console.log(routeData)
    return {
      routeData,
      clearUrl,
      routeError,
      routeSearch
    };
  },
});
